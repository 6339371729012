import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useState } from 'react'
import { NavLink } from 'react-router-dom';



const fixedtop = {


  position: "fixed !important ",
  top: " 28px !imortant",
  right: "0",
  left: "0",
  zIindex: "1030",
};

const MenuBar = () => {


  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);





  const showDropdown = (e) => {
    setShow(!show);
  }
  const hideDropdown = (e) => {
    setShow(false);
  }
  const showDropdown1 = (e) => {
    setShow1(!show1);
  }
  const hideDropdown1 = (e) => {
    setShow1(false);
  }



  const showDropdown2 = (e) => {
    setShow2(!show2);
  }
  const hideDropdown2 = (e) => {
    setShow2(false);
  }

  const showDropdown3 = (e) => {
    setShow3(!show3);
  }
  const hideDropdown3 = (e) => {
    setShow3(false);
  }
  const showDropdown4 = (e) => {
    setShow4(!show4);
  }
  const hideDropdown4 = (e) => {
    setShow4(false);
  }



  const showDropdown6 = (e) => {
    setShow6(!show6);
  }
  const hideDropdown6 = (e) => {
    setShow6(false);
  }



  const showDropdown5 = (e) => {
    setShow5(!show5);
  }
  const hideDropdown5 = (e) => {
    setShow5(false);
  }



  return (

    <>

      {/* <!-- Header Top --> */}

      <header className="main-header header-style-one" id='hide_mobile'>
        {/* <!-- Header Top --> */}
        <div className="header-top">
          <div className="auto-container">
            <div className="inner clearfix">
              <div className="top-left clearfix">
                <ul className="info clearfix">
                  <li><NavLink to="tel:678-920-6669"><span className="icon fa fa-phone-alt"></span>  &nbsp;678-920-6669</NavLink></li>
                  <li><NavLink to="mailto:info@yugalkunj.org"><span className="icon fa fa-envelope-open"></span> Email: &nbsp;info@yugalkunj.org</NavLink></li>

                </ul>
              </div>

              <div className="top-right  temple-time clearfix">
                <ul className="info clearfix">
                  <li><NavLink to="https://www.facebook.com/yugalkunj/" target="_blank"><span className="icon fa fa-clock"></span>  Mon - Fri : 7:00 AM -
                    8:00 AM,  5:00 PM - 8:00 PM</NavLink></li>

                  {/* <li><NavLink to="https://www.facebook.com/yugalkunj/" target="_blank"><span className="icon fa fa-clock"></span> Mon - Fri : 5:00 PM - 8:00 PM </NavLink></li> */}

                  <li><NavLink to="https://www.facebook.com/yugalkunj/" target="_blank"><span className="icon fa fa-clock"></span>   Sat - Sun : 7:00 AM - 8:00 PM </NavLink></li>
                </ul>
                <ul className="social-links clearfix">
                  <li><NavLink to="https://www.facebook.com/yugalkunj/" target="_blank"><span className="fab fa-facebook-f"></span></NavLink></li>
                  <li><NavLink to="https://twitter.com/search?q=yugalkunj" target="_blank"><span className="fab fa-twitter"></span></NavLink></li>
                  <li><NavLink to="https://www.yelp.com/biz/yugal-kunj-duluth-2" target="_blank"><span className="fab fa-yelp"></span></NavLink></li>
                  <li><NavLink to="https://www.youtube.com/channel/UC-3Ydnp9Z5ER1jz7_gZVDQA" target="_blank"><span className="fab fa-youtube"></span></NavLink></li>
                  <li><NavLink to="https://www.instagram.com/yugal_kunj/" target="_blank"><span className="fab fa-instagram"></span></NavLink></li>

                  <li><NavLink to="https://video.search.yahoo.com/search/video;_ylt=AwrC1DGkf51cEi8A_gzQtDMD;_ylu=X3oDMTB0N2Noc21lBGNvbG8DYmYxBHBvcwMxBHZ0aWQDBHNlYwNwaXZz?p=yugal+kunj+duluth+ga&fr2=piv-web&fr=yfp-t-s" target="_blank"><span className="fab fa-yahoo"></span></NavLink></li>





                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Navbar collapseOnSelect expand="lg" className='navbarbg' style={{}}>
        <Container>

          <Navbar.Brand href="/" className='logo'>
            <img src='https://stssevastorage.blob.core.windows.net/ykwebsite/logo.png' className="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
            <Nav className="me-auto">
              <Nav.Link href="/USA/Home">Home </Nav.Link>
              {/* <Nav.Link href="/MyGallery" >Gallery</Nav.Link> */}
              <Nav.Link href="/USA/about">About Us</Nav.Link>
              <NavDropdown
                title="DIDI JI "
                className="dropdown"
                id="collasible-nav-dropdown" show={show}
                onMouseEnter={showDropdown}
                onMouseLeave={hideDropdown}>

                <NavDropdown.Item href="/USA/aboutdidiji" >About Didi Ji</NavDropdown.Item>
                <NavDropdown.Item href="/USA/videolectures">
                  Video Lectures
                </NavDropdown.Item>
                <NavDropdown.Item href="/USA/wordsofwisdom">Words of Wisdom</NavDropdown.Item>


              </NavDropdown>

              {/* <Nav.Link href="/USA/Gallery">GALLERY</Nav.Link> */}
              <NavDropdown title="REGISTRATION " id="collasible-nav-dropdown" show={show1}
                onMouseEnter={showDropdown1}
                onMouseLeave={hideDropdown1}>
                <NavDropdown.Item href="https://ykportal.yugalkunj.org/?ParamCountry=USA" target="_blank">Retreat</NavDropdown.Item>
                <NavDropdown.Item href="https://ykportal.yugalkunj.org/?ParamCountry=USA" target="_blank">
                  Camp
                </NavDropdown.Item>
                <NavDropdown.Item href="https://ykportal.yugalkunj.org/?ParamCountry=USA" target="_blank">Class</NavDropdown.Item>
                <NavDropdown.Item href="https://ykportal.yugalkunj.org/?ParamCountry=USA" target="_blank">Sports</NavDropdown.Item>
                <NavDropdown.Item href="https://ykportal.yugalkunj.org/?ParamCountry=USA" target="_blank">Cooking & Prasad Seva</NavDropdown.Item>


              </NavDropdown>
              {/* <NavDropdown title="Seva " id="collasible-nav-dropdown" show={show2}
                onMouseEnter={showDropdown2}
                onMouseLeave={hideDropdown2}>
                <NavDropdown.Item href="ykportal.yugalkunj.org" target="_blank" > Cooking & Prasad seva </NavDropdown.Item>




              </NavDropdown> */}


              <NavDropdown title="Event" id="collasible-nav-dropdown" show={show3}
                onMouseEnter={showDropdown3}
                onMouseLeave={hideDropdown3}>
                <NavDropdown.Item href="/USA/upcomingevents" >Upcoming Events</NavDropdown.Item>
                {/* <NavDropdown.Item href="/USA/Blogs" >Our Blogs</NavDropdown.Item> */}
                <NavDropdown.Item href="/USA/templeactivities">
                  Temple Activities
                </NavDropdown.Item>
                <NavDropdown.Item href="/USA/activitycalendar">Yugal Kunj Calendar</NavDropdown.Item>


              </NavDropdown>
               <NavDropdown title="VOLUNTEER " id="collasible-nav-dropdown" show={show4}
                onMouseEnter={showDropdown4}
                onMouseLeave={hideDropdown4}>
                <NavDropdown.Item href="/USA/volunteerregistration" >Volunteer Registration</NavDropdown.Item>
                <NavDropdown.Item href="https://payments.paysimple.com/PaySimple/login/checkoutformlogin/SEUJm5e-Ezlt8lSVxZGJbG2zKNY-" target="_blank">
                  Donate
                </NavDropdown.Item>



              </NavDropdown> 

              <NavDropdown title="FACILITIES " id="collasible-nav-dropdown" show={show5}
                onMouseEnter={showDropdown5}
                onMouseLeave={hideDropdown5}>
                <NavDropdown.Item href="/USA/directions" >Directions</NavDropdown.Item>
                <NavDropdown.Item href="/USA/eventrentals">
                  Event Rentals
                </NavDropdown.Item>
                <NavDropdown.Item href="https://ykportal.yugalkunj.org/?ParamCountry=USA" target="_blank">Catering & Dining</NavDropdown.Item>
                <NavDropdown.Item href="/USA/contact">Contact</NavDropdown.Item>
                {/* <NavDropdown.Item href="/USA/Component/ContactUS">Contact US</NavDropdown.Item> */}




              </NavDropdown>


              <NavDropdown title="Country " id="collasible-nav-dropdown" show={show6}
                onMouseEnter={showDropdown6}
                onMouseLeave={hideDropdown6}>
                {/* <NavDropdown.Item href="/CAD/Direction" >Directions</NavDropdown.Item> */}
                {/* <NavDropdown.Item href="/CAD/eventrentals">
                  Event Rentals
                </NavDropdown.Item> */}
                {/* <NavDropdown.Item href="https://ykportal.yugalkunj.org/Tiffin/Home">Catering & Dining</NavDropdown.Item> */}
                <NavDropdown.Item href="/CAD/Home">Canada</NavDropdown.Item>




              </NavDropdown>

              <div className="donate-link">
                {/* <a href="https://payments.paysimple.com/Buyer/CheckOutFormPay/wTEp3uzwUq544RHSP02hH7cz81k-" className="theme-btn btn-style-one" ><span className="btn-title">Donate
                  Now</span></a> */}

                <a href="https://payments.paysimple.com/PaySimple/login/checkoutformlogin/SEUJm5e-Ezlt8lSVxZGJbG2zKNY-" className="theme-btn btn-style-one" ><span className="btn-title">Donate
                  Now</span></a>
              </div>
            </Nav>

          </Navbar.Collapse>

        </Container>
      </Navbar>



    </>

  )


}

export default MenuBar;