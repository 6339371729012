const ServiceData = [
    

   

    {
        id: 18,
        imgsrc: "https://yugalkunj.org/images/YugalKunjexpansion.jpg",
        title: "Yugal Kunj expansion  ",
        Desc: "Click here for more details on Yugal Kunj expansion ",
        Link: "/USA/YugalKunjexpansion",
        RegistrationLink: "/USA/YugalKunjexpansion",
        Readbtn: "Read More",
        registerbtn: "Read More",
        target:""

    },
    {
        id: 26,
        imgsrc: "https://yugalkunj.org/images/janmashtmi.jpeg",
        title: "Janmashtami ",
        Desc: "Click here for more details on Janmashtami ",
        Link: "/USA/upcomingevents",
        RegistrationLink: "/USA/upcomingevents",
        Readbtn: "Read More",
        registerbtn: "Read More",
        target:"_"

    },

    {
        id: 25,
        imgsrc: "https://yugalkunj.org/images/3daysKeertan.jpeg",
        title: "3 Days Continuous Keertan",
        Desc: "Click here for more details on 3 Days Continuous Keertan ",
        Link: "/USA/upcomingevents",
        RegistrationLink: "/USA/upcomingevents",
        Readbtn: "Read More",
        registerbtn: "Read More",
        target:"_"

    },

    // {
    //     id: 17,
    //     imgsrc: "https://yugalkunj.org/images/MahaShivRatri.jpeg",
    //     title: "Maha Shivratri ",
    //     Desc: "Click here for more details on Maha Shivratri ",
    //     Link: "/USA/upcomingevents",
    //     RegistrationLink: "/USA/upcomingevents",
    //     Readbtn: "Read More",
    //     registerbtn: "Upcoming Event",
    //     target:""

    // },



    // {
    //     id: 18,
    //     imgsrc: "https://yugalkunj.org/images/springbreakcampkids.jpeg",
    //     title: "Spring Break Kids Camp ",
    //     Desc: "Click here for more details on Spring Break Kids Camp ",
    //     Link: "/USA/Camp/springbreakkidscamp",
    //     RegistrationLink: "https://ykportal.yugalkunj.org/?ParamCountry=USA",
    //     Readbtn: "Read More",
    //     registerbtn: "Register Now",
    //     target:""

    // },

    

    // {
    //     id: 20,
    //     imgsrc: "https://yugalkunj.org/images/Summer_camp_2024.png",
    //     title: "Summer Camp  ",
    //     Desc: "Click here for more details on Summer Camp ",
    //     Link: "/USA/Camp/SummerCamp",
    //     RegistrationLink: "https://ykportal.yugalkunj.org/?ParamCountry=USA",
    //     Readbtn: "Read More",
    //     registerbtn: "Register Now",
    //     target:""

    // },
    {
        id: 19,
        imgsrc: "https://yugalkunj.org/images/milwaukee_summer_camp.png",
        title: "Milwaukee Summer Camp ",
        Desc: "Click here for more details on Milwaukee Summer Camp ",
        Link: "/USA/Camp/MilwaukeeSummerCamp",
        RegistrationLink: "https://ykportal.yugalkunj.org/?ParamCountry=USA",
        Readbtn: "Read More",
        registerbtn: "Register Now",
        target:""

    },
   
   

    // {
    //     id: 17,
    //     imgsrc: "https://yugalkunj.org/images/FamilyRetreat.jpg",
    //     title: "Family Day Weekend Retreat ",
    //     Desc: "Click here for more details on Family Day Weekend Retreat ",
    //     Link: "/USA/upcomingevents",
    //     RegistrationLink: "/USA/upcomingevents",
    //     Readbtn: "Read More",
    //     registerbtn: "Upcoming-Events",
    //     target:""

    // },

    // {
    //     id: 1,
    //     imgsrc: "https://yugalkunj.org/images/philospy.png",
    //     title: "Philosophy of Divine Bless ",
    //     Desc: "Click here for more details on Philosophy of Divine Bless  ",
    //     Link: "/USA/upcomingevents",
    //     RegistrationLink: "/USA/upcomingevents",
    //     Readbtn: "Read More",
    //     registerbtn: "Upcoming-Events",
    //     target:""

    // },
   

    // {
    //     id: 2,
    //     imgsrc: "https://yugalkunj.org/images/yoga.png",
    //     title: "Yoga & Pranayam ",
    //     Desc: "Click here for more details on Yoga & Pranayam in Yugal Kunj  ",
    //     Link: "/USA/upcomingevents",
    //     RegistrationLink: "https://ykportal.yugalkunj.org/",
    //     Readbtn: "Read More",
    //     registerbtn: "Register",
    //     target:"_"

    // },
    

    // {
    //     id: 3,
    //     imgsrc: "https://yugalkunj.org/images/dance.png",
    //     title: "Break Dance Hip Hop" ,
    //     Desc: "Click here for more details on Break Dance Hip Hop  ",
    //     Link: "/USA/upcomingevents",
    //     RegistrationLink: "https://ykportal.yugalkunj.org/Home/CountrySelect",
    //     Readbtn: "Read More",
    //     registerbtn: "Register",
    //     target:""

    // },
    // {
    //     id: 4,
    //     imgsrc: "https://yugalkunj.org/images/carnatic.png",
    //     title: "Classical Music" ,
    //     Desc: "Click here for more details on Classical Music  ",
    //     Link: "/USA/upcomingevents",
    //     RegistrationLink: "https://ykportal.yugalkunj.org/Home/CountrySelect",
    //     Readbtn: "Read More",
    //     registerbtn: "Register",
    //     target:""

    // },

    

    // {
    //     id: 6,
    //     imgsrc: "https://stssevastorage.blob.core.windows.net/my-container/Financial%20Planning%20Class%20Flyer%202%20-%20800x800.jpg",
    //     title: "Finance Planning Concepts ",
    //     Desc: "Click here for more details on Finance Planning Concepts in Yugal Kunj  ",
    //     Link: "https://ykportal.yugalkunj.org/",
    //     RegistrationLink: "https://ykportal.yugalkunj.org/",
    //     Readbtn: "Read More",
    //     registerbtn: "Register",
    //     target:"_"

    // },
   
   
    // {
    //     id: 7,
    //     imgsrc: "https://yugalkunj.org/images/Class2.jpg",
    //     title: "Crochet Class ",
    //     Desc: "Click here to get more info and register for Crochet Class ",
    //     Link: "https://ykportal.yugalkunj.org/",
    //     RegistrationLink: "https://ykportal.yugalkunj.org/",
    //     Readbtn: "Read More",
    //     registerbtn: "Register",
    //     target:"_"

    // },

  
    // {
    //     id: 8,
    //     imgsrc: "https://yugalkunj.org/images/HYC_Acadmy.jpg",
    //     title: "HYC Academy",
    //     Desc: "Click here to more details  ... ",
    //     Link: "https://harmonyyouthcenter.org/",
    //     RegistrationLink: "https://harmonyyouthcenter.org/",
    //     Readbtn: "Read More",
    //     registerbtn: "Register",
    //     target:"_blank"

    // },

    // {
    //     id: 9,
    //     imgsrc: "https://yugalkunj.org/images/RotiFlyer.jpeg",
    //     title: "Fresh Roti",
    //     Desc: "Click here to order fresh roti ... ",
    //     Link: "https://ykportal.yugalkunj.org/Tiffin/Home",
    //     RegistrationLink: "https://ykportal.yugalkunj.org/Tiffin/Home",
    //     Readbtn: "Read More",
    //     registerbtn: "Order Now",
    //     target:""

    // },

    {
        id: 10,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsite/resource/cause-image-3.jpg",
        title: "Order Prasad",
        Desc: "Prasad is sacred vegetarian food shared with devotees after it has been lovingly prepared and offered to God...",
        Link: "https://ykportal.yugalkunj.org/?ParamCountry=USA",
        RegistrationLink: "https://ykportal.yugalkunj.org/?ParamCountry=USA",
        Readbtn: "Read More",
        registerbtn: "Order",
        target:""

    },



    {
        id: 11,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsite/resource/featured-image-6.jpg",
        title: "Yugal Kunj Visit",
        Desc: "Click here to register for Yugal Kunj visit registration.",
        Link: "https://ykportal.yugalkunj.org/?ParamCountry=USA",
        RegistrationLink: "https://ykportal.yugalkunj.org/?ParamCountry=USA",
        Readbtn: "Read More",
        registerbtn: "Register",
        target:""

    },



    {
        id: 12,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsite/resource/Geeta-Beginner.png",
        title: "Classes",
        Desc: "Radha Madhav Society offers Gurukul classes to open young minds to spirituality and the richness of Hinduism in a very ...",
        Link: "https://ykportal.yugalkunj.org/?ParamCountry=USA",
        RegistrationLink: "https://ykportal.yugalkunj.org/?ParamCountry=USA",
        Readbtn: "Read More",
        registerbtn: "Register",
        target:""

    },



    {
        id: 13,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsite/resource/inspirational-1.jpg",
        title: "Words of Wisdom",
        Desc: "Spiritual nuggets from the Words of Wisdom that are written by Didi Ji and sent out every Friday.",
        Link: "/USA/wordsofwisdom",
        RegistrationLink: "/CAD/wordsofwisdom",
        Readbtn: "Read More",
        registerbtn: "Read Words of Wisdom",
        target:""

    },


    {
        id: 14,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsite/ykseva.png",
        title: "Cooking  Seva",
        Desc: "Click here to register for cooking  Seva.",
        Link: "https://ykportal.yugalkunj.org/?ParamCountry=USA",
        RegistrationLink: "https://ykportal.yugalkunj.org/?ParamCountry=USA",
        Readbtn: "Read More",
        registerbtn: "Register",
        target:""

    },


    {
        id: 15,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsite/resource/about-2.jpg",
        title: "Flowery Grove Montessori School",
        Desc:"We offer genuine Montessori education in a safe, natural setting with serene campus and beautifully prepared classrooms",
        // Desc: "We provide authentic Montessori education in a natural and safe environment. The serene campus, beautifully prepared classroom",
        Link: "https://flowerygrovemontessori.org/",
        RegistrationLink: "https://flowerygrovemontessori.org/",
        Readbtn: "Read More",
        registerbtn: "Register",
        target:""

    },

    // {
    //     id: 16,
    //     imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsite/resource/HomeworkHelp.png",
    //     title: "Homework Help",
    //     Desc: "Harmony youth center (HYC) is a non-profit incorporation dedicated to serving the communit.",
    //     Link: "https://harmonyyouthcenter.org/",
    //     RegistrationLink: "https://harmonyyouthcenter.org/",
    //     Readbtn: "Read More",
    //     registerbtn: "Register",
    //     target:""


    // }







];

export default ServiceData; 