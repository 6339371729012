
const WordofWisdomeCard = () => {
  
// const qaData = [
//     { question: "What never goes out of style?", answer: "Kindness." },
//     { question: "What makes a person attractive?", answer: "Confidence and Genuineness." },
//     { question: "How can I get rid of anxiety?", answer: "Let go of expectations" },


//     { question: " How can I stop worrying?", answer: "By having faith that everything is happening for a reason." },
//     { question: "How must I serve God?", answer: "With gratitude and humility." },
//     { question: "What can I do to avoid envy?", answer: "Convert it into admiration." },
//     { question: "What is the importance of prayer?", answer: "It is food and water for the soul." },
//     { question: "What is more precious than gold?", answer: "True friendship." },
//     { question: "Who should I be cautious about?", answer: "Those who flatter you." },


//     { question: "Why am I not moving ahead in my spiritual life?", answer: "Procrastination" },
//     { question: "What will help me find time for devotional practice?", answer: "Accepting that peace and happiness are the byproducts of devotion." }
  




    


    
//   ];



    return (

        <>

            {/* <!--Sidebar Page Container--> */}
            <div className="sidebar-page-container">
                <div className="auto-container">
                    <div className="row clearfix">

                        {/* <!--Content Side / Blog Detail--> */}
                        <div className="content-side col-lg-12 col-md-12 col-sm-12">
                            <div className="post-details">
                                {/* <!--Blog Details--> */}
                                <div className="blog-details">


                                    <div className="lower-box">


                                        <div className="panel panel-primary">
                                            <div className="panel panel-heading">
                                                {/* <h4>Words of Wisdom - Question & Answer Session - #140   </h4> */}
                                                <h4>Words of Wisdom - Best Things to Give </h4>

                                            </div>

                                            <div className="panel panel-body">
                                                <div className='row'>
                                                    <div className="col-md-12">

                                                        <p> Giving to others brings immense satisfaction to the mind. There is a great need for givers in the world; there always has been and will always be. We are children of the most magnanimous and generous God who never tires of giving. Let’s try to follow in His footsteps. I will begin by giving some suggestions:</p>
                                                        <ul className="wordofwisdon_list">
                                                            <li>  Give your attention. Listen carefully to what people are saying, especially children and the elderly. We often tend to dismiss them.
                                                            </li>

                                                            <li> Give your time. Best gift is the gift of time. We all claim not to have enough time; so giving it means you are giving a very precious gift.</li>

                                                            <li>  Give benefit of the doubt. We do disservice to others by jumping to conclusions. We give due respect to others by giving them benefit of the doubt.</li>
                                                            <li>  Give a smile. You can brighten a dull day with your smile. You can lift someone’s spirits with your sweet smile. Smile, please.</li>
                                                            <li>  Give a surprise. Send flowers for no reason. Give a surprise visit. Surprise someone going through hardship with a hearty meal or by cleaning up their home and doing their laundry.</li>
                                                            <li>  Give your help. If you are a handyman, help those who need something done around the house but are physically unable to do it. If you are young and healthy, extend a helping hand to the elderly and the physically ill. </li>
                                                            <li>   Give financial help. In the present climate your friends and family members may not have enough to even pay the rent or the mortgage; so don’t wait for them to ask. Extend financial help without being asked.</li>
                                                      
                                                      
                                                      
                                                      
                                                        </ul>
                                                      

                                                    {/* <div>
      {qaData.map((item, index) => (
        <div key={index} >
          <h5> Q: {item.question}</h5>
          <p style={{fontSize: '20px', borderBottom:"1px solid #ccc" }}> A: {item.answer}</p>
        </div>
      ))}
    </div> */}
                                                     

                                                        <hr />
                                                        <p> Yours,</p>
                                                        <p> Didi Ji</p>
                                                        <p className="text-center"> Note: You may direct your spiritual questions to me
                                                            directly at</p>
                                                        <p className="text-center"> s_didi@radhamadhavsociety.org</p>


                                                    </div>

                                                </div>

                                            </div>


                                        </div>




                                    </div>






                                </div>





                            </div>

                        </div>


                    </div>
                </div>
            </div>
            {/* <!-- End Sidebar Page Container --> */}



        </>


    )

}

export default WordofWisdomeCard;